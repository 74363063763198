<template>
	<v-card max-width="800" class="mx-auto mt-5" :loading="loading || loadingParent" rounded="xl">
		<v-card-title>{{ $t('companies.representative') }}</v-card-title>
		<v-card-text>
			<p>{{ $t('companies.currentRepresentative') }}</p>
			<v-list v-cloak>
				<v-list-item>
					<v-list-item-avatar>
						<v-icon>mdi-account</v-icon>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>{{ company.contact.name.full }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item>
					<v-list-item-avatar>
						<v-icon>mdi-briefcase-account</v-icon>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>{{ company.contact.contact.position }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item>
					<v-list-item-avatar>
						<v-icon>mdi-phone</v-icon>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>{{ company.contact.contact.phone }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item>
					<v-list-item-avatar>
						<v-icon>mdi-email-outline</v-icon>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>{{ company.contact.contact.email }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<CompanyContactChangeForm :loading-parent="loadingParent || loading" />
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'CompanyContact',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	components: {
		CompanyContactChangeForm: () => import('@/components/companies/CompanyContactChangeForm.vue')
	},
	computed: {
		joinedAdministrators() {
			return this.company.administrators.administrators.filter((admin) => admin.joined)
		},
		...mapGetters({
			company: 'companies/company'
		})
	}
}
</script>
